<template>
    <Alert v-for="(a, index) in alert" :key="a" :data="a" @closeAlert="closeAlert(index)"/>
    <TopNavigation step="0" title="Pending Subscriber" icon="cart-shopping" />

    <div class="generalBorderlessBox">
        <div class="row mb-4">
            <div class="col-12 col-lg-6 pb-2">
                <div class="input-group">
                    <span class="input-group-text"><fa icon="search" /></span>
                    <input type="text" v-model="filterName" class="form-control" placeholder="Name">
                    <span v-if="filterName && filterName.length > 0" class="input-group-text clearSearch" @click="filterName = ''">
                        <fa icon="xmark" />
                    </span>
                </div>
            </div>

            <div class="col-12 col-lg-6 pb-2 text-end">
                <span class="text-primary isLink me-2" @click="$router.push({ path: '/backoffice/subscriber' })">
                    <fa icon="cart-plus" class="me-2"/>Subscriber
                </span>
                <span class="text-primary isLink" @click="$router.push({ path: '/backoffice/subscriber/report' })">
                    <fa icon="chart-line" class="me-2"/>Subscriber Report
                </span>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="generalContentBox border shadow-sm">
                    <div class="row">
                        <div class="col-12" ref="scrollComponent">
                            <div v-if="isLoading" class="mb-3 text-center">
                                <fa icon="spinner" size="lg" class="me-2" />Loading...
                            </div>

                            <div v-if="!isLoading" class="mb-3 text-end small fst-italic">100 of 10,000</div>
            
                            <div class="mb-3" v-if="checkedUser && checkedUser.length > 0">
                                <span class="mx-2 text-grey isLink"><fa icon="trash" size="lg" style="padding-left: 9px" /></span>
                                <span class="float-end text-grey fw-bold">{{checkedUser.length}} selected</span>
                            </div>

                            <table class="table table-hover" border="0">
                                <tbody>
                                    <tr v-for="(item, itemIdx) in arrDgItem" :key="item">
                                        
                                        <td width="50px" class="text-center">
                                            <!-- <div class="text-start text-danger small">{{item}}</div> -->
                                            <!-- Large screen -->
                                            <div class="d-none d-md-block">
                                                <input v-model="checkedUser" type="checkbox" class="form-check-input" :value="item.userId" />
                                            </div>
                                            <!-- End Large screen -->

                                            <!-- Small screen -->
                                            <div class="d-block d-md-none">
                                                <span class="hoverHide" :id="'avatar' + itemIdx">
                                                    <ImgAvatar :key="itemIdx" :isUser="true" id="" displayName="" :isVerified="item.isVerified" :isActive="item.status" privilege="" width="30" height="30" />
                                                    <!-- <Avatar :key="itemIdx" isUser="true" avatar="" displayName="" :isVerified="item.isVerified" :isActive="item.status" width="30" height="30" /> -->
                                                </span>
                                            </div>
                                            <!-- End Small screen -->
                                        </td>
                                    
                                        <td class="align-middle">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <span class="fw-bold">{{item.fullName}}</span>
                                                    <span class="text-grey"><fa icon="at" class="mx-1" />{{ item.displayName }}</span>
                                                </div>
                                                <div class="col-4 col-lg-2 small text-grey">{{item.userId}}</div>

                                                <div class="col-8 col-lg-3 small text-grey text-end">
                                                    <Popper class="popperDark" arrow hover content="Date expiry" placement="top">
                                                    {{ func.convDateTimeFormat(item.dateDelete, 'datetime')}}
                                                    </Popper>
                                                </div>
                                                
                                                <div class="col-lg-1 text-end d-none d-md-block hoverShow">
                                                    <span class="text-grey isLink me-2">
                                                        <Popper class="popperDark" arrow hover content="Delete">
                                                            <fa icon="trash" size="lg" />
                                                        </Popper>
                                                    </span>
                                                </div>
                                                <!-- Small screen -->
                                                <div class="col-12 text-end d-block d-md-none pt-2">
                                                    <span class="text-grey isLink me-4">
                                                        <fa icon="trash" size="lg" />
                                                    </span>
                                                    <span>
                                                        <input v-model="checkedUser" type="checkbox" class="form-check-input" :value="item.userId" />
                                                    </span>
                                                </div>
                                                <!-- End Small screen -->
                                            </div>
                                        </td>
                                    </tr>

                                    <tr v-if="isLoadingMore" class="mt-3 text-center">
                                        <td colSpan="2">
                                            <fa icon="spinner" size="lg" class="me-2" />Loading...
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, inject, onMounted, onUnmounted, watch } from 'vue'
import TopNavigation from '@/components/TopNavigation.vue'
import Alert from '@/components/Alert.vue'
import { useRouter, useRoute } from 'vue-router'
import Popper from 'vue3-popper'
import funcs from '@/functions/function'
import { useStore } from '@/stores/store'
// import Avatar from '@/components/Avatar.vue'
import ImgAvatar from '@/components/ImgAvatar.vue'

export default {
    name: 'BOPendingSubscriber',/*  */
    components: { Alert, TopNavigation, Popper, ImgAvatar /* Avatar */ },
    setup () {
        const axios = inject('axios')
        const alert = ref([])
        const route = useRoute()
        const router = useRouter()
        const func = funcs
        const store = useStore()

        const scrollComponent = ref(null)
        
        const dgMode = ref('all')  // all: load all, part: query db part by part
        const dgCutOffRcd = ref(30)  // decide dgMode if >= dgCutOffRcd part, else all
        const total = ref(0)
        const totalShown = ref(0)   // total records has been displayed
        const rcdFr = ref(0)        // record index from
        const rcdDisplay = ref(15)  // total record to display per scroll, ORIG: 15
        const isLoading = ref(true)
        const isLoadingMore = ref(false)

        const filterName = ref('')

        const arrItem = ref([])       // for dgMode=all, different fr arrDgItem
        const arrFilterItem = ref([]) // for dgMode=all only - handle filter and remain orig data in arrItem
        const arrDgItem = ref([])     // record shown in dg, total length shown as totalShown

        const clsAvatar = ref('d-block')
        const clsCheckbox = ref('d-none')
        const checkedUser = ref([])
        
        const closeAlert = (index) => {
            alert.value.splice(index, 1)
        }

        watch([filterName], () => {

            if (dgMode.value === 'all') {
                console.info('- - WATCH all', filterName.value.length)
                if (filterName.value.length === 0) {
                    iniDg() // reset filter

                } else if (filterName.value.length >= 3) {
                    dgAllFilter()
                    proDgAllData()
                }
            }
            else {
                console.info('- - WATCH part', filterName.value.length)
                if (filterName.value.length === 0) {
                    arrItem.value = []
                    arrDgItem.value = []
                    iniDg() // reset filter

                } else if (filterName.value.length >= 3) {
                    arrDgItem.value = []
                    getList()
                }
            }
        })


        const dgAllFilter = () => {
            console.info('- - func: dgAllFilter')
            let arrTmp = []
            for(var i = 0; i < arrItem.value.length; i++) {
                if ( arrItem.value[i].fullName.toUpperCase().includes(filterName.value.toUpperCase()) === true || 
                     arrItem.value[i].displayName.toUpperCase().includes(filterName.value.toUpperCase()) === true) {
                    arrTmp.push(arrItem.value[i])
                }
            }

            total.value = arrTmp.length
            totalShown.value = 0
            rcdFr.value = 0

            arrFilterItem.value = arrTmp
            arrDgItem.value = []
        }

        const handleScroll = (e) => {
            
            let element = scrollComponent.value
            if (element !== null) {
                // console.info('- - handleScroll', element.getBoundingClientRect().bottom.toFixed(0), window.innerHeight)
                if (element.getBoundingClientRect().bottom.toFixed(0)*1 < window.innerHeight) {
                    
                    let tmp = rcdFr.value + rcdDisplay.value
                    // console.info('- handleScroll', 'tmp', tmp, '<', 'total', total.value,'| rcdFr PRE', rcdFr.value)
                    if (tmp < total.value) {
                        
                        isLoadingMore.value = true
                        rcdFr.value = rcdFr.value + rcdDisplay.value
                        // console.info('- - - - - handleScroll load more | rcdFr POST', rcdFr.value)
                        if (dgMode.value === 'all') {

                            proDgAllData()
                            isLoadingMore.value = false
                        }
                        else
                        {
                            getList()
                            isLoadingMore.value = false
                            
                        }
                    }
                }
            }
        }

        const iniDg = async () => {
            total.value = 0
            totalShown.value = 0
            rcdFr.value = 0

            arrItem.value = []
            arrDgItem.value = []

            getListIni()
        }

        // Get initial list, without search criteria & rcd limit to decide dgMode
        const getListIni = async () => {

            isLoading.value = true

            let datagridMode = dgMode.value 
            await axios.post('/report/su/user/list', { status: 'PENDING'})
            .then((res) => {

                if (res.data.status === 1) {
                    total.value = res.data.description.rowCount

                    if (total.value >= dgCutOffRcd.value) {
                        console.info('- - - part', res.data.data.length)
                        datagridMode = 'part'
                        arrItem.value = res.data.data
                        proDgAllData() // set arrDgItem to get partial data fr arrItem(full, no search criteria & rcd limit)
                        arrItem.value = arrDgItem.value  // Set back arrItem same as arrDgItem for 
                    } 
                    else 
                    {
                        // console.info('- - - all', JSON.stringify(res.data.data))
                        datagridMode = 'all'
                        arrItem.value = res.data.data
                        proDgAllData()
                    }
                } 
                isLoading.value = false
            })
            .catch((error) => {

                console.log('getListIni | ERR', error)
                isLoading.value = false
            })

            dgMode.value = datagridMode
        }

        // For dgMode = part - to get more list
        const getList = async () => {

            let p = { 
                limit: rcdFr.value + ',' + rcdDisplay.value,
                status: 'PENDING' 
            }

            if (filterName.value !== '' && filterName.value.length >= 3) { 
                p.name = filterName.value
            }

            console.info('- - func: getList', 'REQ', JSON.stringify(p))

            axios.post('/report/su/user/list', p)
            .then((res) => {
                if (res.data.status === 1) {
                    total.value = res.data.description.rowCount
   
                    if (res.data.data.length > 0) {
                        console.info('- - - getList RES', res.data.data.length, JSON.stringify(res.data.data), 'REQ', JSON.stringify(p))
                        let tmp = []
                        tmp = res.data.data

                        for (var i = 0; i < tmp.length; i++) {
                            arrDgItem.value.push(tmp[i])
                        }

                        totalShown.value = arrDgItem.value.length
                        arrDgItem.value = arrDgItem.value
                    }
                }
                    
            })
            .catch((error) => {
                console.log('getList | ERR', error)
            })

        }

        // for dgMode = part, initial access once only
        // for dgModel = all, access when initial access, scroll more, filter
        const proDgAllData = async () => {

            let len = rcdFr.value + rcdDisplay.value

            if (filterName.value.length >= 3) {
                // console.info('- - - proDgAllData - filter', rcdFr.value, rcdDisplay.value, 'arrFilterItem', arrFilterItem.value.length, JSON.stringify(arrFilterItem.value))
                for (var i = rcdFr.value; i < len; i++) {
                    if (arrFilterItem.value[i] !== undefined) {
                        arrDgItem.value.push(arrFilterItem.value[i])
                    }
                }
                totalShown.value = arrDgItem.value.length
            }
            else
            {
                // console.info('- - - proDgAllData', rcdFr.value, rcdDisplay.value, 'len', len)
                for (var i = rcdFr.value; i < len; i++) {
                    if (arrItem.value[i] !== undefined) {
                        arrDgItem.value.push(arrItem.value[i])
                    }
                }
                totalShown.value = arrDgItem.value.length
            }
        }

        const DUMP_resetSearch = () => {

            totalFilter.value = 0

            filterName.value = ''
            filterStatus.value = ''
            filterTeam.value = ''
            filterStorageFull.value = false
            
            rcdFr.value = 0
            arrItem.value = []
        }

        const DUMP_getTotal = () => {
            total.value = 8
        }

        const DUMP_getList = (loadMore) => {

            isLoading.value = false
            isLoadingMore.value = false

            arrItem.value = [
                {
                    userId: 'alex.hang@yahoo.com',
                    firstName: 'Kar Meng',
                    lastName: 'Hang',
                    nickname: 'Alex.Hang',
                    avatar: '',
                    isVerified: false,
                    tags: ['colleague', 'development'],
                    status: 'active',
                    package: 'personal',
                    dateExpiry: '2023-01-05T01:15:10.000Z',
                    entityName: 'SignOn.my'
                },
                {
                    userId: 'mohamad1234@yahoo.com',
                    firstName: 'Mohamad bin Abdullah',
                    lastName: '',
                    nickname: 'Mohamad',
                    avatar: '',
                    isVerified: true,
                    tags: ['colleague', 'cs'],
                    status: 'active',
                    package: 'personal',
                    dateExpiry: '2023-01-04T05:23:10.000Z',
                    entityName: 'Genusis'
                },
                {
                    userId: 'grace@gmail.com',
                    firstName: 'Shu Min',
                    lastName: 'Tan',
                    nickname: 'Grace',
                    avatar: '',
                    isVerified: false,
                    tags: [],
                    status: 'inactive',
                    package: 'Free',
                    dateExpiry: '2023-01-01T10:55:10.000Z',
                    entityName: 'AC'
                },
                {
                    userId: 'kelly@gmail.com',
                    firstName: 'Hai Yan',
                    lastName: 'Kek',
                    nickname: 'Kelly Kek',
                    avatar: '',
                    isVerified: false,
                    tags: ['mcis'],
                    status: 'inactive',
                    package: 'Free',
                    dateExpiry: '2022-12-25T00:24:10.000Z',
                    entityName: 'Genusis'
                },
                {
                    userId: 'jason.chan@yahoo.com',
                    firstName: 'Chun Kiat',
                    lastName: 'Lim',
                    nickname: 'Jason',
                    avatar: '',
                    isVerified: false,
                    tags: ['unifi'],
                    status: 'active',
                    package: 'personal',
                    dateExpiry: '2022-12-20T04:13:10.000Z',
                    entityName: 'AC'
                },
                {
                    userId: 'joey1980@gmail.com',
                    firstName: 'Joey',
                    lastName: 'Tan',
                    nickname: 'Joey80',
                    avatar: '',
                    isVerified: false,
                    tags: ['mcis', 'management'],
                    status: 'active',
                    package: 'personal',
                    dateExpiry: '2022-11-13T08:09:10.000Z',
                    entityName: 'AC'
                },
                {
                    userId: 'patrick@gmail.com',
                    firstName: 'Xin Zhan',
                    lastName: 'Teoh',
                    nickname: 'Patrick',
                    avatar: '',
                    isVerified: false,
                    tags: ['colleague', 'support'],
                    status: 'active',
                    package: 'Free',
                    dateExpiry: '2022-10-28T06:31:10.000Z',
                    entityName: 'AC'
                },
                {
                    userId: 'yennee.lim@genusis.com',
                    firstName: 'Yen Nee',
                    lastName: 'Lim',
                    nickname: 'YNLim',
                    avatar: '',
                    isVerified: true,
                    tags: ['colleague', 'development'],
                    status: 'active',
                    package: 'personal',
                    dateExpiry: '2022-05-11T10:02:10.000Z',
                    entityName: 'Genusis'
                },

            ]

        }

        onMounted(() => {
            window.addEventListener("scroll", handleScroll)
            iniDg()

            // rcdFr.value = 0
            // getTotal()
            // getList(false)

        })

        onUnmounted(() => {
            window.removeEventListener("scroll", handleScroll)
        })

        return {
            alert, closeAlert, route, router, func, store, 
            total, clsAvatar, clsCheckbox, checkedUser, 
            dgMode, dgCutOffRcd, total, totalShown, rcdFr, rcdDisplay, isLoading, isLoadingMore, 
            filterName, arrItem, arrDgItem, arrFilterItem, 
        }
    }
}
</script>

<style>
.table-hover > tbody > tr .hoverShow {
    visibility: hidden;
}

.table-hover > tbody > tr:hover .hoverShow {
    visibility: visible;
}

.table-hover > tbody > tr .hoverHide {
    visibility: visible;
}

.table-hover > tbody > tr:hover .hoverHide {
    visibility: hidden;
}

.input-group-text {
    background-color: var(--bs-primary);
    color: var(--bgcolor-body-color);
}

.filter-border {
    border: 1px solid var(--accent-color);
    border-radius: 5px; 
    font-size: 14px;
    padding: 7px 15px;
    margin-bottom: 10px;
}

.filter-pill-border {
    border: 1px solid var(--accent-color);
    border-radius: 5px; 
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 10px;
}

.filter-item-pill {
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
}

.filter-item-pill:hover {
    color: var(--bs-primary);
}

.filter-item-pill:active, .filter-item-pill.active {
    color: var(--bgcolor-body-color);
    background-color: #53BDEB;
    border-radius: 5px;
    padding: 9px 15px;
    cursor: default;
    margin: 0px;
}

.filter-item-pill:disabled, .filter-item-pill.disabled {
    color: var(--toolbar-hr);
    border-radius: 5px;
    padding: 9px 15px;
    cursor: default;
    margin: 0px;
}

.filter-item-storage {
    cursor: pointer;
}

.filter-item-storage:hover {
    color: var(--bs-primary);
}

.clearSearch {
  cursor: pointer; 
  color: #53BDEB;
  background-color: var(--bgcolor-body-color); 
  border-color: var(--accent-color)
}
</style>